import PluginCss from '!raw-loader!@egjs/react-flicking/dist/flicking.css';
import brand from '@helpers/brand';
import { fluid } from '@helpers/fluid';
import { Device, from, until } from '@helpers/media';
import { ThemeSwitch } from '@helpers/theme';
import NextButtonStyles from '@stories/Components/Buttons/NextButton/NextButton.styles';
import PrevButtonStyles from '@stories/Components/Buttons/PrevButton/PrevButton.styles';
import { DocumentCardStyles } from '@stories/Components/Cards/DocumentCard/DocumentCard.styles';
import HeadingStyles from '@stories/Components/Global/Typography/Heading.styles';
import { ImageStyles } from '@stories/Components/Media/Image/Image.styles';
import styled from 'styled-components';
const Container = styled.div `
  overflow: hidden;
  margin: 40px 0px;
`;
const Inner = styled.div `
  grid-column: span 12;
  position: relative;
  z-index: 2;

  ${HeadingStyles.Heading} {
    grid-column: span 12;
    text-align: center;
    ${ThemeSwitch({ groundforceColour: brand.primary.alt })}
    color: var(--theme);
    margin-bottom: 28px;
  }
`;
const Title = styled.h2 `
  ${HeadingStyles.H3Styles}
`;
const Intro = styled.p `
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin: 0 auto 30px;

  @media ${from(Device.Tablet)} {
    font-size: 18px;
    line-height: 28px;
    max-width: 670px;
    margin: 0 auto 80px;
  }
`;
const ItemList = styled.div `
  --itemGutter: ${fluid(10, 30)};
  --featureGutter: 0px;

  --itemWidth: 1;
  --itemSize: 1;
  --itemSpace: 0;

  --featureWidth: 100%;
  --featureSize: 1;

  ${PluginCss};

  display: flex;
  width: 100%;

  .flicking-viewport {
    width: 100%;
    // Stops the overflow cutting the box-shadow
    padding: 8px;
    margin-right: -8px;
    margin-left: -8px;

    @media ${until(Device.TabletLarge)} {
      overflow: visible;
    }
  }

  @media ${from(Device.Tablet)} {
    --itemWidth: 2;
    --itemSpace: 1;
    --itemSize: 1;

    --featureWidth: 100%;
    --featureSize: 2;
  }

  @media ${from(Device.TabletLarge)} {
    --itemWidth: 3;
    --itemSpace: 2;
    --itemSize: 1;

    --featureWidth: 66%;
    --featureSize: 2;
  }

  @media ${from(Device.Desktop)} {
    --itemWidth: 4;
    --itemSpace: 3;
    --itemSize: 1;

    --featureWidth: 50%;
    --featureGutter: 30px;
    --featureSize: 2;
  }
`;
const Item = styled.div `
  display: block;
  margin-right: var(--itemGutter);
  /* flex: 1 0 calc(var(--itemWidth) - (var(--itemGutter) / 2)); */

  flex: 1 0 calc((100% - (var(--itemGutter) * var(--itemSpace))) / var(--itemWidth));

  &[data-featured='true'] {
    flex: 1 0 calc(var(--featureWidth) - (var(--featureGutter) / 2));
  }

  --imageAspectRatio: 387 / 246;
  --imageMaxHeight: 246px;

  &[data-featured='true'] {
    --imageAspectRatio: 804 / 299;
    --imageMaxHeight: 299px;
  }

  @media ${until(Device.MobileLarge)} {
    max-width: 100%;
    height: auto;
  }

  ${DocumentCardStyles.Card} {
    height: 100% !important;
  }

  ${DocumentCardStyles.Media} {
    aspect-ratio: var(--imageAspectRatio);
    max-height: var(--imageMaxHeight);

    ${ImageStyles.Responsive} {
      height: 100%;
    }
  }
`;
const CtaWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 22px 0 0;

  @media ${from(Device.Tablet)} {
    justify-content: center;
    margin: 64px 0 0;
  }
`;
const Controls = styled.div `
  display: grid;
  grid-template-columns: 40px 40px 1fr 40px 40px;
  margin: 24px 0 0;
  gap: 12px;
  ${ThemeSwitch({ groundforceColour: brand.primary.alt })}

  @media ${from(Device.Tablet)} {
    grid-template-columns: 48px 48px 1fr 48px 48px;
    margin: 40px 0 0;
  }

  ${PrevButtonStyles.Button} {
    background-color: var(--theme);
    grid-column: 4;
  }

  ${NextButtonStyles.Button} {
    background-color: var(--theme);
    grid-column: 5;
  }
`;
const CarouselDot = styled.button.attrs({ type: 'button' }) `
  display: flex;
  flex: 1 0 auto;
  border: none;
  border-radius: 0;
  height: 5px;
  padding: 0;
  background-color: ${brand.grey.grey89};
  transform-origin: center bottom;
  transition: background-color 0.15s ease-out, transform 0.15s ease-out;
  max-width: 132px;

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    transform: scaleY(2);
    ${ThemeSwitch({ groundforceColour: brand.primary.alt })}
    background-color: var(--theme);
  }

  &[disabled] {
    ${ThemeSwitch({ groundforceColour: brand.primary.alt })}
    background-color: var(--theme);
  }
`;
const DotHolder = styled.div `
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  gap: 6px;
  grid-column: 1 / span 3;

  @media ${until(Device.TabletLarge)} {
    &[data-hidden='1'] {
      display: none;
    }
  }

  @media ${from(Device.Tablet)} {
    gap: 10px;
  }

  @media ${from(Device.TabletLarge)} {
    grid-column: 3;
  }
`;
const Base = styled.div `
  grid-column: span 12;
  position: relative;
  z-index: 2;
  padding: 30px 0;
  text-align: center;
`;
export default {
    Base,
    CarouselDot,
    Container,
    Controls,
    CtaWrapper,
    DotHolder,
    Inner,
    Intro,
    Item,
    ItemList,
    Title,
};
